import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
//import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
//import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import whyRelic from "images/whyRelic.png";
import Securitize from "images/securitize.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        //subheading={<Subheading>Features</Subheading>}//
        heading={<>Why <HighlightedText>Relic?</HighlightedText></>}
      />
      <MainFeature
        //subheading={<Subheading>Quality Work</Subheading>}//
        imageSrc={whyRelic}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        //subheading={<Subheading>STEPS</Subheading>}//
        heading={<>Easy to <HighlightedText>Get Started.</HighlightedText></>}
        textOnLeft={false}
        imageSrc={Securitize}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
{/*     <Testimonial
         subheading={<Subheading></Subheading>}
         heading={
           <>
             <HighlightedText></HighlightedText>
           </>
         }
         testimonials={[
           {
             stars: 0,
             profileImageSrc:"",
             heading: "",
             quote:"",
             customerName: "",
             customerTitle: ""
           },
           {
             stars: 0,
             profileImageSrc:"",
             heading: "",
             quote:"",
             customerName: "",
             customerTitle: ""
           },
         ]}
       /> 
*/} 

      <FAQ
        //subheading={<Subheading>FAQS</Subheading>}//
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Why a Security Token?",
            answer:
              "Transparency - everything on the blockchain is auditable and the ledger is publicly available making it secure and transparent. Availability - old financial markets run on a fixed time and only available during normal business hours. A security token is available at all times irrespective of time. Divisibility - assets tokenized can be divisible to thousands of pieces making it easy for everyone to invest in. Instant Settlement - old markets have a archaic process of settlement, which takes time and resources. Using the blockchain, the transactions are settled instantly."
          },
          {
            question: "What is Asset-Backed Token?",
            answer:
              "Asset-backed tokens are digital assets with characteristics similar to any commodity, such as gold, real estate, stocks, bonds, etc. Key difference being on the blockchain, which makes them immutable, secure and transparent."
          },
          {
            question: "How is value being added?",
            answer:
              "The token is backed by an actual asset that is bringing in cash flow, interest, dividends and capital gains, which, in turn, bring value to Relic."
          },
          {
            question: "Will the Security Token be liquid?",
            answer:
              "The security token will be liquid between investors immediately and everyone will be able to exchange it freely when it is listed on an exchange."
          },
          {
            question: "Are my investments safe and secure?",
            answer:
              "We use the highest level of security to safeguard all funds in a multi-sig bank account until the funds need to be allocated towards investments. The token is held in our cold storage wallet that is protected by our partner, Securitize."
          },
          {
            question: "Where can I reach you for support?",
            answer:
              "You can reach us directly by email at support@relicventures.com or use the chat service during normal business hours."
          }
        ]}
      />

      <Footer />
    </AnimationRevealPage>
  );
}
